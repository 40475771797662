<template>
  <!-- Buyer Info -->
  <div class="project-sales-buyer-info mt-3 p-2">
    <h4 class="mb-3">Buyer</h4>
    <div class="row mb-2">
      <display-data
        class="col-12 sm-col-6 md-col-4 px-1"
        label="Buyer Name"
        :content="reservation.purchaserName1"
      >
      </display-data>
      <display-data
        class="col-12 sm-col-6 md-col-4 px-1"
        label="Buyer Email"
        :content="reservation.email"
      >
      </display-data>
      <display-data
        class="col-12 sm-col-6 md-col-4 px-1"
        label="Buyer Contact"
        :content="reservation.phoneContact"
      >
      </display-data>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData")
  },
  mixins: [],
  props: {
    reservation: {
      type: Object,
      default: () => ({
        purchaserName1: "",
        email: "",
        phoneContact: ""
      })
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sales-buyer-info {
  .buyer-name {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
